import React from "react"
import { graphql } from "gatsby"

import Layout from "../landing/layouts/LayoutAlternative";
import Home from "../landing/views/Home";
import SEO from "../components/seo";

import '../landing/index.js';

const NotFoundPage = (props) => {
  const siteTitle = props.data.site.siteMetadata.title;

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Crisp Invoices" />
      <Home {...props} />
    </Layout>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
