import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string
}

const defaultProps = {
  src: undefined,
  width: undefined,
  height: undefined,
  alt: undefined
}

class Image extends React.Component {

  state = {
    isLoaded: false,
  };

  image = React.createRef();

  componentDidMount() {
  }

  placeholderSrc = (w, h) => {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}"%3E%3C/svg%3E`;
  }

  render() {

    const {
      className,
      src,
      width,
      height,
      alt,
      ...props
    } = this.props;

    return (
      <img
        {...props}
        ref={this.image}
        className={className}
        src={src}
        width={width}
        height={height}
        alt={alt} />
    );
  }
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;